<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <div class="learn">

      <van-search
      v-if="subTypeOption.length == 0"
      v-model="obj.FilterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-row v-if="subTypeOption.length > 0">
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="obj.FilterData.subTypeIds"
            :options="subTypeOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="obj.FilterData.title"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>
      <van-swipe :autoplay="3000" style="height:150px;width:100%" v-if="images.length">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img
            :src="item.imageUrl"
            style="width:100%;height:100%"
            @click="$router.push(item.urlPath)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...
    </van-loading>
    <div v-if="loading == false">
      <div
        style="padding:10px 10px 10px 30px;font-size:14px;border-bottom:1px solid #eee"
        v-for="item in list"
        :key="item.id"
        @click="toclick(item.id)"
      >
        <van-row>
          <van-col span="8"
            >
          <van-image
              lazy-load
              class="w100"
              fit="fill"
              style="width:100px;max-height: 200px"
              :src="item.imageUrl"
              
            >
           </van-image>
          </van-col>
          <van-col
            span="16"
            style="font-size:15px;color:#333;font-weight:700;padding-left:30px; line-height: 60px;"
            >{{ item.title }} <br />
            <div style="padding-top:3px" v-if="typeid!=401">数量：{{ item.itemCount }}</div>
            <!-- <div style="padding-top:3px">课程介绍：{{ item.detailsText }}</div> -->
          </van-col>
        </van-row>
        <!-- <img :src="item.imageUrl" alt="" style="" />
        {{ item.title }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { getList2API } from "../../api/coursePackage";
import {  getArticleTypeList} from "@/api/article";
// import resultVue from "../search/result.vue";

export default {
  name: "coursePackets",
  data() {
    return {
      typeid:this.$route.query.typeid,
      subTypeOption: [],
      title: this.$route.query.typeid==401?"经验包":"课程包",
      // post请求携带参数
      obj: {
        fromType: 1,
        current: 1,
        pageSize: 10,
        FilterData: {
          title: "",
          subTypeIds: "",
        },
      },
      body: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [207],
          // label: "讲师 轮播图",
        },
        // value: 208,
      },
      //   初始化数据
      list: [],
      //   展开值
      activeName: this.$route.query.id,
      loading: true,
      finished: false,
      // 搜索框
      value: "",
      id: this.$route.query.id,
      images: [],
    };
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.query.id !== from.query.id) {
  //       this.loading = false;
  //       this.initgetList2();
  //     }
  //   },
  // },

  computed: {},
  created() {
   
    this.onSearch();
  },

  methods: {
    
    
    async onSearch() {

      let typeid=400;
    if(this.$route.query.typeid)
    {
      typeid=this.$route.query.typeid
    }

      let result = await getList2API(this.obj,typeid);
      let { data } = result.data;
      // console.log(data);
      this.list = data;
      this.loading = false;
      // console.log(result);

      let subTypeList = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: typeid,
        },
      });
      if (subTypeList.data.data.length > 0) {
        this.subTypeOption = [{ text: "全部", value: "" }];
        subTypeList.data.data.map((x) =>
          this.subTypeOption.push({ text: x.typeName, value: x.id.toString() })
        );
      }

    },
    

    toclick(id) {
      this.$router.push({
        path: "/course/coursePacketsItems",
        query: { id: id },
        date: new Date().getTime(),
      });
    },
    // async getListItemsAPI() {},

    // toClick(type, id) {
    //   id = id.toString();
    //   if (type === 1) {
    //     this.$router.push({
    //       path: "/course/detail",
    //       query: { para: id },
    //       date: new Date().getTime(),
    //     });
    //   }
    //   if (type === 2) {
    //     this.$router.push({
    //       path: "/course/courseofflinedetail",
    //       query: { para: id },
    //       date: new Date().getTime(),
    //     });
    //   }
    // },
    // 数字转中文
    numberToChinese(num) {
      var AA = new Array(
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
      );
      var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
      var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
      for (let i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
          case 0:
            re = BB[7] + re;
            break;
          case 4:
            if (
              !new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$").test(a[0])
            )
              re = BB[4] + re;
            break;
          case 8:
            re = BB[5] + re;
            BB[7] = BB[5];
            k = 0;
            break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
          re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
      }

      if (a.length > 1) {
        // 加上小数部分(如果有小数部分)
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
      }
      if (re == "一十") re = "十";
      if (re.match(/^一/) && re.length == 3) re = re.replace("一", "");
      return re;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  overflow-y: auto;
  box-sizing: border-box;
}
.loading {
  text-align: center;
}
/deep/ van-list {
  margin-top: 2000px;
}
.cell {
  height: 30px;
  font-size: 16px;
  color: black;
}
.value-class {
  font-size: 20px;
}
</style>
